<template>
  <div>

    <!-- Header Section -->
    <panelheader v-if="sidePanelDataObj.headerDataObj" :header-data-obj="sidePanelDataObj.headerDataObj"></panelheader>

    <infoFilterDetails v-if="sidePanelDataObj.info" :data-obj="sidePanelDataObj"></infoFilterDetails>

    <!-- Inventory Details -->
    <inventorydetails v-if="sidePanelDataObj.data" :data-obj="sidePanelDataObj"></inventorydetails>

    <!-- Tabs Section -->
    <tabs v-if="sidePanelDataObj.iFrame" :data-obj="sidePanelDataObj" :tab-data-obj="sidePanelDataObj.tabSectionDataObj"
      :iFrame="sidePanelDataObj.iFrame"></tabs>

  </div>
</template>

<script>
//Standard Components
import EVENTS from "@/constants/events";

//Project Earth
import sidePanelIFrame from "@/components/_universal/sidepanel/sidepanel-iframe";

//Template Layout Components
import panelheader from "@/components/_universal/sidepanel/sidepanel-header";
import actionbar from "@/components/_universal/sidepanel/sidepanel-action-bar";
import panelsummary from "@/components/_universal/sidepanel/sidepanel-summary-info";
import tabs from "@/components/_universal/sidepanel/sidepanel-tabs";

import infoFilterDetails from "@/components/infoFilters/infoFilterDetails.vue";

import inventorydetails from "@/components/inventory/inventory-details.vue";

//Controller
import InventoryController from "@/controllers/inventory-controller";

//Initialize Controller and pass data to be loaded/displayed
const inventoryController = new InventoryController();


export default {
  data() {
    return {
      sidePanelDataObj: {},
      EVENT_ACTION: {},
      iframeUrl: null
    };
  },
  methods: {
    async loadData() {
      let sidePanel = this.$store.getters.getSidePanel;


      //Ensure the side bar is open
      if (sidePanel.isOpen === true) {

        //Load data from store, for side panel
        //Set Header Properties to be passed in as prop values
        this.sidePanelDataObj = await inventoryController.main(sidePanel.data);

        //Check if Side Panel should load Iframe
        if (this.sidePanelDataObj.isSidePanelFrame) {
          this.iframeUrl = this.sidePanelDataObj.url;
        }
      }
    }
  },
  components: {
    panelheader,
    inventorydetails,
    infoFilterDetails,
    tabs,
  },
  async mounted() {
    //ONLY TRACK EVENTS IN PRODUCTION
    // if (process.env.VUE_APP_ENVIRONMENT === "production") {
    //   window["analytics"].page(this.EVENT_ACTION.INVENTORY.View);
    // }
  },
  created() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    this.loadData();
  }
};
</script>

