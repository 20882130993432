<template>
  <div>
    <!-- Action Bar -->
    <div class="c-card u-p-small u-mb-small mx-2 mb-0 pb-2 pt-3 sticky-card height-auto">
      <div>
        <!-- Header Section -->
        <panelheader :id="$route.name+'-header'" v-if="sidePanelDataObj.headerDataObj"
          :header-data-obj="sidePanelDataObj.headerDataObj"></panelheader>
      </div>

      <div class="embed-responsive embed-responsive-16by9 documentPreview">
        <iframe :id="$route.name+'-iframe'" frameborder="0" scrolling="yes"
          style="height:1300px;width:100%;border:none;" :src="iframesrc"></iframe>
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapState } from "vuex";

//Template Layout Components
import panelheader from "@/components/_universal/sidepanel/sidepanel-header";

import EVENTS from "@/constants/events";

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    sidePanelDataObj: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      EVENT_ACTION: {},
      iframesrc: "",

    };
  },
  methods: {
    //Initialize Data
    loadData() {
      let frame = this.$refs.sidePanelframe;

      this.$nextTick(() => {
        this.iframesrc = this.url;
      });
    },
  },

  mounted() {
    //Import event constants and set local variable
    this.EVENT_ACTION = EVENTS;

    this.loadData();
  },
  components: {
    panelheader
  }
};
</script>
<style>
iframe {
  height: 100vh;
  width: 100%;
}
</style>
